import { ReactNode } from 'react'
import { Col, Row } from 'antd'
import { Text20Normal } from 'components/@atomics'
import styled from 'styled-components'

import { SCREEN_MEDIA_QUERY } from '@constants/constLayout'

type LayoutBodyProps = {
  children: ReactNode
  width?: number | string
  maxWidth?: number
  minWidth?: number
}

const LayoutLogo = ({ children, width = 420, maxWidth, minWidth }: LayoutBodyProps) => {
  return (
    <StyledContainer width={width} maxWidth={maxWidth} minWidth={minWidth}>
      <Row justify="center" style={{ paddingBottom: 17 }}>
        <Text20Normal>Fitpetmall Admin</Text20Normal>
      </Row>
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<LayoutBodyProps>`
  margin: auto;
  width: ${({ width }) => width}px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  min-width: ${({ minWidth }) => minWidth}px;
  padding-top: 104px;
  padding-bottom: 100px;

  label {
    font-size: 16px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #333;
    background-color: #333;
  }

  @media ${SCREEN_MEDIA_QUERY.xs} {
    padding-top: 40px;
    /* max-width: 95%;   */
  }
`

export default LayoutLogo
