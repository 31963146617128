import { NextPage } from 'next'
import LayoutLogo from 'components/@atomics/layouts/LayoutLogo'
import { FormSignin } from 'domains/common/forms'

const Login: NextPage = () => {
  return (
    <LayoutLogo>
      <FormSignin />
    </LayoutLogo>
  )
}

export default Login
